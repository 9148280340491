import { Component, Vue } from "vue-property-decorator";

@Component({})
export default class TableCheckMinxi extends Vue {
  mix_selectedRow: any[] = [];
  mix_handleCheckAll(tableDataList, ref) {
    tableDataList.forEach(item => {
      (this.$refs[ref] as any).toggleRowSelection(item, true);
    });
  }
  mix_handleCancelAll(ref) {
    (this.$refs[ref] as any).clearSelection();
  }
  mix_handleRowSelect(selection, row) {
    this.mix_selectedRow = selection;
  }
}
