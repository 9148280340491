
import { Component, Mixins } from "vue-property-decorator";
import { namespace } from "vuex-class";
import tableCheck from "@/mixins/table-check";
import TableEnableFlag from "@/components/scope/table-enable-flag.vue";
import { emptyToLine } from "@/utils/common";
import dayjs from "dayjs";
import { Pageniation, Loading } from "@/utils/decorator";
import t from "@common/src/i18n/t";
const announcement = namespace("announcement");
const base = namespace("base");
const consult = namespace("consult");
const learning = namespace("learning");
const management = namespace("management");

@Component({
  components: { TableEnableFlag }
})
@Pageniation("loadListData")
export default class TenantList extends Mixins(tableCheck) {
  @base.Action getDictionaryList;
  @announcement.Action getAnnouncementList;
  @announcement.Action endAnnouncement;
  @consult.Action queryUserAccountList;
  @learning.Action queryExamPaperList;
  @learning.Action queryscoringList;
  @management.Action getStoreList;
  dayjs = dayjs;
  visible: boolean = true;
  searchForm = {
    examPaperTempCode: "",
    userName: "",
    storeCode: "",
    examResult: "",
    startSubmitTime: "",
    endSubmitTime: ""
  };
  emptyToLine = emptyToLine;
  answerSheetList: any[] = [];
  examList: any = [];
  storeTypeList: any[] = [];
  examResultList: any = [];
  storeList: any = [];
  get searchList() {
    return [
      {
        label: t("v210831.test-name-not"),
        type: "select",
        value: "",
        prop: "examPaperTempCode",
        selectList: this.examList
      },
      {
        label: t("v210831.candidate-name"),
        type: "input",
        value: "",
        prop: "userName"
      },
      {
        label: t("v210831.store"),
        type: "select-search",
        value: "",
        prop: "storeCode",
        selectList: this.storeList,
        searchFun: this.searchStore
      },
      {
        label: t("v210831.is-it-qualified"),
        type: "select",
        value: "",
        prop: "examResult",
        selectList: this.examResultList
      },
      {
        label: t("v210831.submission-date"),
        type: "daterange",
        value: "",
        prop: "startSubmitTime"
      }
    ];
  }
  //多行文本省略
  filters(value) {
    if (!value) return "";
    if (value.length > 20) {
      return value.slice(0, 20) + "...";
    }
    return value;
  }
  handlefinished(finishdata) {
    this.endAnnouncement(finishdata).then(() => {
      this.$message.success(t("v210831.operation-succeeded"));
      this.loadListData();
    });
  }
  searchStore(val = "") {
    this.getStoreList({
      pageNum: 1,
      pageSize: 10,
      storeName: val
    }).then(data => {
      this.storeList = data.data.list
        ? data.data.list.map(item => {
            item.label = item.storeName;
            item.value = item.storeCode;
            return item;
          })
        : [];
    });
  }
  handleSearch(data) {
    this.mix_pageNum = 1;
    data.forEach(item => {
      if (item.prop) {
        if (item.prop === "startSubmitTime") {
          this.searchForm.startSubmitTime = item.value ? item.value[0] : "";
          this.searchForm.endSubmitTime = item.value ? item.value[1] : "";
        } else {
          this.searchForm[item.prop] = item.value;
        }
      }
    });
    this.loadListData();
  }
  created() {
    this.init();
  }
  @Loading()
  loadListData() {
    return this.queryscoringList({
      ...this.searchForm,
      pageNum: this.mix_pageNum,
      pageSize: this.mix_pageSize
    }).then(data => {
      this.answerSheetList = data.data.list;
      return data;
    });
  }
  async init() {
    this.loadListData();
    this.searchStore();
    this.getDictionaryList("store_type").then(data => {
      console.log(data, "appList");
      this.storeTypeList = data;
    });
    this.getDictionaryList("exam_result").then(data => {
      this.examResultList = data;
    });
    this.queryExamPaperList({
      // 查询试卷
      pageNum: 1,
      pageSize: 999
    }).then(data => {
      this.examList = (data.data.list || []).map(item => {
        item.label = item.examPaperTempName;
        item.value = item.examPaperTempCode;
        return item;
      });
    });
  }
  handleView(row) {
    this.$router.push(`/learning/exam/scoro-view/${row.answerSheetCode}`);
  }
  handleLinkScoring(row) {
    this.$router.push(`/learning/exam/scoring/${row.answerSheetCode}`);
  }
}
